import React, { useState } from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import { Collapse } from "react-collapse"

import Section from "components/Section"

import BgServiceWomen from "images/bg-service-women.jpg"
import BgServiceMen from "images/bg-service-men.jpg"

import HrmIcon from "images/icons/icon-hrm.png"
import HrdIcon from "images/icons/icon-hrd.png"
import InnovationIcon from "images/icons/icon-innovation.png"
import OdIcon from "images/icons/icon-od.png"
import ManufacturingIcon from "images/icons/icon-manufacturing.png"

import { ArrowRightIcon } from "@heroicons/react/solid"
import { Fade, Flip } from "react-reveal"

const PROGRAMS = [
  {
    key: "human-resource-management",
    label: "인적자원관리 (HRM)",
    icon: HrmIcon,
    description:
      "조직, 전략, 문화, 구성원, 업무 프로세스 등 기업의 특성과 정합되도록 HRM 시스템을 구축함으로서  구성원의 동기부여를 강화하고 조직의 성과 달성을 지원합니다",
    url: "/services/human-resource-management",
  },
  {
    key: "human-resource-development",
    label: "인적자원개발 (HRD)",
    url: "/services/human-resource-development",
    icon: HrdIcon,
    description:
      "급변하는 환경속에서 기업에 필요한 인재 및 역량을 명확히 정의하고, 효과적으로 개발할 수 있는 시스템을 구축하여 인적자원 경쟁력 확보를 지원합니다.",
  },
  {
    key: "od",
    label: "조직개발 (OD)",
    url: "/services/organization-development",
    icon: OdIcon,
    description:
      "기업 핵심경쟁력 및 조직역량을 고려한 조직체계, 프로세스, 인적자원 및 조직문화 혁신 활동을 통해 기업의 생산성을 향상하고, 성공적인 변화를 지원합니다",
  },
  {
    key: "innovation",
    label: "일터혁신",
    url: "/services/innovation",
    icon: InnovationIcon,
    description:
      "협력적 노사관계를 기반으로 근로자의 참여에 의하여 작업조직과 작업방식을 지속적으로 개선함으로써 생산성과 근로생활의 질 향상을 지원합니다.",
  },
  {
    key: "manufacturing",
    label: "제조혁신",
    url: "/services/manufacturing",
    icon: ManufacturingIcon,
    description:
      "제조혁신 및 스마트 기술을 이용한 디지털 혁신 활동과 연계하여 고객사 생산특성에 적합한 제조혁신체계 구축을 지원합니다.",
  },
]

export default function ServicesCollaspe() {
  const [active, setActive] = useState(0)

  return (
    <Section>
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap -mx-4">
          <div className="w-full lg:w-5/12 flex items-center px-4">
            <div>
              <div className="mb-8">
                <Section.Headline eyebrow={"Our Services"}>
                  {/* <Flip bottom cascade> */}
                  <span className="break-words">
                    HR 전 분야에 걸친 다양한 컨설팅 서비스와 생산성 혁신
                    서비스를 연계한 통합 솔루션으로 기업 경쟁력을 강화합니다.
                  </span>
                  {/* </Flip> */}
                </Section.Headline>
              </div>
              {PROGRAMS.map((item, index) => {
                return (
                  <div
                    key={`service-${item.key}`}
                    className="mb-5 group cursor-pointer"
                    onClick={() => setActive(index)}
                  >
                    <Fade>
                      <div className="flex flex-row items-center">
                        <div className="w-12 h-12 bg-gray-100 inline-block rounded-xl mr-4 relative overflow-hidden">
                          <div style={{ paddingBottom: "100%" }}></div>
                          {item?.icon && (
                            <img
                              src={item.icon}
                              alt={item.label}
                              className="absolute inset-0 w-full h-full object-cover p-1"
                            />
                          )}
                        </div>
                        <h4 className="transition duration-200 inline-block text-xl font-medium text-gray-900 group-hover:text-primary">
                          {item.label}
                        </h4>
                      </div>
                      <Collapse isOpened={active === index}>
                        <div className="pt-3 pl-16">
                          <Link to={item.url}>
                            <p className="max-w-xl text-gray-700 break-words mb-3">
                              {item.description}
                            </p>
                            <div className="text-primary inline-flex flex-row flex-wrap items-center">
                              더 알아보기{" "}
                              <ArrowRightIcon className="w-4 h-4 ml-1 transition group-hover:translate-x-1" />
                            </div>
                          </Link>
                        </div>
                      </Collapse>
                    </Fade>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="hidden lg:flex w-full lg:w-7/12 px-4 items-center justify-end relative">
            <div>
              <Parallax y={[0, 20]} tagOuter="figure">
                <Fade fraction={0.4}>
                  <div className="px-4">
                    <div className="relative overflow-hidden bg-gray-100 w-72 xl:w-80 2xl:w-96">
                      <div style={{ paddingBottom: "140%" }}></div>
                      <img
                        src={BgServiceWomen}
                        alt=""
                        className="w-full h-full absolute inset-0 object-cover"
                      />
                    </div>
                  </div>
                </Fade>
              </Parallax>
            </div>

            <Parallax y={[120, 20]} tagOuter="figure">
              <Fade>
                <div className="-ml-8">
                  <div className="relative overflow-hidden bg-gray-100 w-48 xl:w-64 2xl:w-72">
                    <div style={{ paddingBottom: "140%" }}></div>
                    <img
                      src={BgServiceMen}
                      alt=""
                      className="w-full h-full absolute inset-0 object-cover"
                    />
                  </div>
                </div>
              </Fade>
            </Parallax>
          </div>
        </div>
      </div>
    </Section>
  )
}
