import React from "react"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

export default function CaseCarousel({ source, renderItem }) {
  return (
    <>
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: ".case-carousel-swiper-button-next",
          prevEl: ".case-carousel-swiper-button-prev",
        }}
        slidesPerView={1.2}
        spaceBetween={16}
        breakpoints
        breakpoints={{
          1024: {
            slidesPerView: 2,
            spaceBetween: 24,
            centeredSlides: false,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 32,
            centeredSlides: false,
          },
        }}
      >
        {source &&
          source.map(item => {
            return (
              <SwiperSlide className="h-auto" key={item.id}>
                {renderItem(item)}
              </SwiperSlide>
            )
          })}
      </Swiper>
    </>
  )
}
