import React from "react"
import { useResizeDetector } from "react-resize-detector"
import { ParallaxBanner } from "react-scroll-parallax"

import HeroImage from "images/hero.jpg"

export default function Hero() {
  const { width, ref } = useResizeDetector()

  return (
    <div ref={ref}>
      <div className="pt-8 lg:pt-24 pb-8">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <h2 className="text-black text-4xl sm:text-5xl lg:text-7xl leading-snug font-bold uppercase tracking-tighter">
            Human & <br className="hidden lg:block xl:hidden" />
            Productivity <br />
            Innovator
          </h2>
          <p className="lg:text-lg text-gray-500 max-w-md break-words mt-3 lg:mt-5">
            HPC컨설팅은 근로자의 성장과 기업의 생산성 혁신을 동시에 추구합니다.
          </p>
        </div>
      </div>
      <ParallaxBanner
        layers={[
          {
            image: HeroImage,
            amount: 0.4,
          },
        ]}
        // style={{ aspectRatio: "2 / 1" }}
        style={{
          height: width > 640 ? "480px" : "240px",
        }}
      />
    </div>
  )
}
