import React from "react"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

export default function MemberCarousel({ source, renderItem }) {
  return (
    <>
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: ".member-carousel-swiper-button-next",
          prevEl: ".member-carousel-swiper-button-prev",
        }}
        slidesPerView={2}
        spaceBetween={16}
        breakpoints
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
            centeredSlides: false,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 32,
            centeredSlides: false,
          },
        }}
      >
        {source &&
          source.map(item => {
            return (
              <SwiperSlide key={item.id}>
                {renderItem && renderItem(item)}
              </SwiperSlide>
            )
          })}
      </Swiper>
    </>
  )
}
